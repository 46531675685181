import React from "react";
import styles from "../styles/components/Particle.module.scss";
function Particle() {

    return (
        <div className={styles['particle-container']}>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
            <div className={styles['particle']}></div>
        </div>
    );
}

export default Particle;